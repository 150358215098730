const LocationReviewsData = [
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "William Pierce",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Phillip W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Chase Fox",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila T.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Hailey Fry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Jared Stanley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sara K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Lydia Jenkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Monique Hartman",
        "service_type": "Garage Door Repair",
        "tech_name": "Evan M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Miss Deanna Ward",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Watson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kim S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Seth Dean",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christian H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Alexander Odonnell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Jamie Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Angela Morgan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Heather Boyd",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Danielle Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Amber Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Ellen Freeman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tyler H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Stevens",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Sandra Walton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Wendy P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Amanda Jenkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Wayne J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Gerald Reed",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melanie B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Kristine Burke",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bobby C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Miguel James",
        "service_type": "Garage Door Repair",
        "tech_name": "Meghan T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Nicole Gordon",
        "service_type": "Garage Door Services",
        "tech_name": "Catherine B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Nancy Newman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Zachary L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Cody Hayes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Erica Campbell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brian S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Mrs. Yolanda Aguirre MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyrone E.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Mark Mitchell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Debbie James",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donna W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Michelle Skinner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Emily Hammond",
        "service_type": "Garage Door Off Track",
        "tech_name": "Glenda A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "John Zamora",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jesse A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Robin Li",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Javier Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Frederick G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Kelly Huang",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ruben S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "David Pierce",
        "service_type": "Garage Door Off Track",
        "tech_name": "Curtis T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Edward Cunningham",
        "service_type": "Garage Door Repair",
        "tech_name": "Norman S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Angela Noble",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Austin W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jennifer Christian",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "George L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Catherine Walter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Morgan Mahoney",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sheila W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Mary Mathis",
        "service_type": "Garage Door Installation",
        "tech_name": "Alec S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Brandi Mathis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Kayla Swanson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Randy Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Angel Carter",
        "service_type": "Garage Door Services",
        "tech_name": "Barbara B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Samantha Thomas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Travis C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Dr. Kelsey Cowan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carolyn S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Frederick Grant",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Reginald Ruiz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Dylan Soto",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Joel Wallace",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cindy P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Adam Meyers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Kevin Sanchez",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "April Holden",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rhonda P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Lindsay Roach",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Denise F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Matthew Cox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lindsay C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Dawn Meyer MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Anna Fitzgerald",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Anthony Dunn",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kara S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "John Wolf",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rebecca R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Cassie Melton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Lindsey Carpenter",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lisa K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Robert Bautista",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Sara Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Courtney G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Oscar Werner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eric N.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Monica Wolf",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Colton W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "John Hill",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Kathleen Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Larry Coleman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Steven Flores",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa O.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Michael Rogers",
        "service_type": "Garage Door Opener",
        "tech_name": "Kevin W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Douglas Rangel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Laura Allen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Aaron Li",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeremy G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Jennifer Lewis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Garrett P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Kimberly Barker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Robinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Allison D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Meagan Fitzgerald",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Samuel Copeland",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Denise S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "David Ruiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Morgan Hill",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ana B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Curtis Chan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Stephanie Rios",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nathaniel H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Tamara Shields",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sonya W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Amy Ortiz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Jose Hogan",
        "service_type": "Garage Door Services",
        "tech_name": "Whitney B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Hannah Ramirez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Foley",
        "service_type": "Garage Door Repair",
        "tech_name": "Carmen M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Daniel Thompson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alex P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Heidi Hart",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michele J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Amy Morgan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ruth M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Justin Burke",
        "service_type": "Garage Door Services",
        "tech_name": "Christina W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Shawn Jackson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Breanna Nolan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Johnny M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Atkinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "April J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Nathan Allison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Blake R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Anne Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Christine Lopez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Victoria Franco",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Joshua Howard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Linda K.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Brianna Benjamin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tina P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Aaron Nguyen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Monica W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Joseph Ramirez DVM",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melanie M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Alexis Montgomery",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carly W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Chelsea Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christina C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Matthew Allison",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary N.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Alexis Sanders",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cassandra J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Emily Harrison",
        "service_type": "Garage Door Off Track",
        "tech_name": "William H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Ricardo Spencer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Jill Stuart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tracy C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Garrison",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Barry George",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dale A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Jesse Robinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Veronica W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "David Clark",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jackson R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Erica Murphy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "James Fox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leon W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Teresa Cameron",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amy A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Robert Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Deborah Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Scott R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Brittany Wells",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Michael Klein",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Bryan Valencia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Kenneth Reid",
        "service_type": "Garage Door Opener",
        "tech_name": "Jamie L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Kayla Bruce",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Garcia DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sherri H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Sarah Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Brandy Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Dr. Molly Sims",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christine R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Andre Walker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Jack Price",
        "service_type": "Garage Door Off Track",
        "tech_name": "Troy D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Tara Campbell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Todd G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Megan S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Chandler",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Kevin Chapman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Jason Gutierrez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Benjamin P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Carr",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Ryan Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Raven C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Jennifer Farrell",
        "service_type": "Garage Door Opener",
        "tech_name": "Emily G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Benjamin Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Angela Cooper",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Samantha B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hunter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diane J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Judy Phillips",
        "service_type": "Garage Door Opener",
        "tech_name": "Larry T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Aaron Riley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alfred C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Ariel Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jesse G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Danielle Vaughn",
        "service_type": "Garage Door Services",
        "tech_name": "Reginald V.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Jill Peterson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Stephanie Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "William Baxter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Donald Ross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Andrea Jordan",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Michael Richardson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Eric Zuniga",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christian M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Sullivan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christian R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Loretta Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Tammy Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexander C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Manuel Mendoza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sydney G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Rachel Rose",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Rachel Haley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leon E.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Allison Porter",
        "service_type": "Garage Door Installation",
        "tech_name": "Ann R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Christian Brady",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Krystal J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Michael Boyle",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Brandon Mcdaniel",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Kelsey Morton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Barbara C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Jenny Roman",
        "service_type": "Garage Door Repair",
        "tech_name": "Tiffany B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lindsey W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Garrett Hunt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric Y.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "William Woods",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jacob C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Eric Greene",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Benjamin B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "David Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Wendy G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Jason Potter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "James Larson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Patrick Rios",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Ryan Willis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tammy P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "William Nunez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephanie M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Laura James PhD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cody B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Mark Randolph",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gabriel M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Joseph Moore",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Misty Osborne",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dan T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Marvin Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Steven C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Dawn Reed",
        "service_type": "Garage Door Services",
        "tech_name": "Aaron W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Sonya Kim",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Conway",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Cynthia Whitaker",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Ms. Jocelyn Kelly DVM",
        "service_type": "Garage Door Services",
        "tech_name": "Erin W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Roy Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jamie G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Ashley Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Taylor Conrad",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Michelle Ball",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Derek Ross DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katie R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Joshua Cook DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joanna H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Karen Velez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eduardo P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Mr. Gary Odonnell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amy R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Erin Sanders",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Raymond Reilly",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Anthony Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stacey R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Rita Holmes",
        "service_type": "Garage Door Services",
        "tech_name": "Michael R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Miranda Fleming MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Craig M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Christina Hayes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kim L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Michele Hamilton",
        "service_type": "Garage Door Services",
        "tech_name": "Meredith F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Peter Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Lisa Stark",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Taylor Green",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Rachel Roberts",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christine J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Stephen Barber",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shelia S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Michael Whitaker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brenda S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Joshua Mendoza",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Linda Roberts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Judy T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Jasmine Adkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dakota F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Daniel Wallace",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anne R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Mary Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Claudia K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Mary Weber",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Adrian Pena",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jerry Hudson",
        "service_type": "Garage Door Installation",
        "tech_name": "Travis J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Sean Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacqueline S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jerry Henson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Tiffany Stewart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael V.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Stephanie Ritter DDS",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brandi R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "William Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "James Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Thomas H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jasmine Cruz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melissa H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Kelley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tony G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Rebecca Howard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Geoffrey M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Katherine Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Cristian Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Colton G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Lucas Hurley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Darlene Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kylie W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Adam Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Kenneth Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Sarah Odom",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Kimberly Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Aaron R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Jason Davidson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Annette A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Caitlin King",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Ray Aguilar",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Curtis H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Jill Jenkins",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Eric Butler",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Charles Hodge",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Lori Beck",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Kristen Bryant",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Louis L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Lauren Roth",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Abigail B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Sanders",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amanda M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Stacy Chase",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Peter P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Heather Cardenas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicole W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Crystal Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Adam Benton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diana H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Carrie Sanchez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Scott Herman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jonathan J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Katherine Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew K.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Lauren Olson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Brian Hopkins",
        "service_type": "Garage Door Services",
        "tech_name": "Roy Y.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Nicole Harrison",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ronnie T.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Rhonda Lewis",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Christopher Nelson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Derek J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Katie Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Doris Beard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Denise S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Margaret Henderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Russell S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Maria Perez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Andrea Montes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tracy M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Peterson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Martin C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Sarah Fry",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samuel D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Raymond Wells",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Kimberly Castillo MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Deborah P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Cindy Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mason J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Theresa Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eugene W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Alyssa Henson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephanie H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Mrs. Katie Wallace",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cindy G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Ryan Holmes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tammy F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Amanda Drake",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tracey G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Angel Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ernest B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "David Becker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Brandon Ramos",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Faith W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Todd Cox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Emily L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sonya K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Kevin Schmidt DDS",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sara M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Kelly",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vanessa P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Shawn Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Mary Gay",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica E.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Danielle Gomez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jared G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Daniel Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Zachary G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Karen Bray",
        "service_type": "Garage Door Services",
        "tech_name": "Samantha H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "William Donovan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Maria Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ernest M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Lisa Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Harold Lopez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Denise W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Ronald Hester",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronnie B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "James Dixon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Bonnie Lambert",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Davidson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Anthony Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Christopher Henry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carl A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Philip Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Steven P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Sabrina Simmons",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hannah F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Samantha Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Hannah R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "John Short",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Barry F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Scott Baker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sean G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Matthew Best",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Christopher Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Charles W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Katie James",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ian M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Michael Combs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Robin Medina",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Patricia Guzman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michelle J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Justin Mullins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Brittany Oliver",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Eric Trujillo",
        "service_type": "Garage Door Installation",
        "tech_name": "Scott M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Erik Morris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Catherine C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jason Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nancy C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Tiffany Farrell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Michael Fuller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Brian Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Barbara M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Tara Hernandez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Thomas A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Michele Crawford",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Brenda Tran",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Holly A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Allison Horton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Thomas H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Beth Parsons",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Curtis P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Carly Thompson",
        "service_type": "Garage Door Services",
        "tech_name": "James N.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Romero",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Ebony Gomez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robyn M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Mikayla Sanders",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Candace Flores",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Angela Cross",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Cheryl Peters",
        "service_type": "Garage Door Services",
        "tech_name": "Richard C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Victor Pena",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dominique B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Michael Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jose W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Susan Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anita S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "John Newman",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristina V.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Antonio Sweeney",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Joseph Wagner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Luis W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Linda Waters",
        "service_type": "Garage Door Services",
        "tech_name": "Courtney L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Monica Green",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony N.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Ellen Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Mary Jenkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carolyn S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Mary Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Scott K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Hunter Hart",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Tiffany Ramirez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Kelly Parker",
        "service_type": "Garage Door Opener",
        "tech_name": "Olivia P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Stephanie Sanchez",
        "service_type": "Garage Door Opener",
        "tech_name": "Tammy D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Dr. Jennifer Burnett",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jason Mendoza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Jennifer Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Mark Reed",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ashley W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Brenda Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Mcmahon",
        "service_type": "Garage Door Repair",
        "tech_name": "Madison L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Perry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Adam Waters",
        "service_type": "Garage Door Installation",
        "tech_name": "Todd D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Abigail Larson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erin A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Angela Brooks",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexander O.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Amber Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Phillip T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Shawn Scott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Eric Mcgrath",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jesse W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Sarah Farrell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christine W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Andrea Adams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Michelle Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "John Cox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Karen Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Douglas M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Jonathan Peters",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kathryn K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Timothy Hoffman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Shelby Irwin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Roger D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Vanessa Hernandez MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Victoria O.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Brandon Stewart",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Misty W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Rebecca Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "David Evans",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Larry Estrada",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Herbert Ho",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jonathan S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Tony Hamilton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Natalie B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Kenneth Thompson",
        "service_type": "Garage Door Services",
        "tech_name": "Aaron L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Howell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Derrick H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Jeremy Gonzalez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Mary Barrett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Edward C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "William Robbins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jimmy M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Christine Arias",
        "service_type": "Garage Door Insulation",
        "tech_name": "Martin G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Teresa Morris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Connor A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Mr. Randy Alexander",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lindsay K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Daniel Yang",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sarah K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Sabrina Douglas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Frank Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Betty M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Barbara Morgan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Angela H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Nicole Wells",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather I.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Katherine Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jerry T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Carol Wilson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Corey P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Mary West",
        "service_type": "Garage Door Opener",
        "tech_name": "John L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ellison",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Colin O.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Kimberly Booth",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tina R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Ashley Richardson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Haley Stewart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dominique J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Brian Thornton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Christopher Espinoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lindsey M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Adam Keller",
        "service_type": "Garage Door Opener",
        "tech_name": "Mark J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Dawn Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Luis O.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Jeremy Henderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Angela Lowe",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Brian Garcia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Jeremy Lowe DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Michael Kemp V",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Pam R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Scott Chase",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carolyn H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Anthony Holmes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christy D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "David Pena",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Dawn Meyers MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Russell King",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua E.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Jaime Anthony",
        "service_type": "Garage Door Services",
        "tech_name": "Christina M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Anthony Heath",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Katherine Parsons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Brandon Ponce",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Marilyn Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert Y.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Faith Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jerry G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Heather Cook",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dustin V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Dawn Nguyen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Carl P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Michelle Perry",
        "service_type": "Garage Door Services",
        "tech_name": "Alex G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Rebecca Landry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Sarah Mcclain",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Olsen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Sanders",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jesse B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Rogers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Brenda Flynn",
        "service_type": "Garage Door Services",
        "tech_name": "Mary H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Kelly Frye",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shawn W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Brian Esparza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alicia S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Sean Humphrey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kristy G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Angela Chapman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Morgan Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Karen H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Cynthia Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Dean Miranda",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeanne F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Donna Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jonathan T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Chang",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Teresa Boyd",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Julie B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Shannon Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Kyle Dyer",
        "service_type": "Garage Door Repair",
        "tech_name": "John J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Rebecca Montgomery",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Roger Y.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Erin Hudson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ian P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Luis Faulkner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nathaniel S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Yvette Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Douglas Harris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Randall T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Tiffany Obrien",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Jeffery Barnes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Elijah R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Hardy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Rickey Lopez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Derek Ingram",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anita G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Connie Thompson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julie G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Andrea Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Theresa S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Dustin Buckley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Hannah H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Greene",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Travis C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jenny Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dennis J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Mccoy",
        "service_type": "Garage Door Repair",
        "tech_name": "Peter W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Jacob Rios",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephen T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Stacie Tate",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Derek Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Teresa J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Mrs. Amber Duncan MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sandra R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Ronald Rodriguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Martin B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Bobby Flynn",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Zachary Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Ashley Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "David Hebert",
        "service_type": "Garage Door Services",
        "tech_name": "Alexander N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Ryan Brewer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Clayton G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Emily Conrad",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tamara D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Rebecca Mullins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Mark Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Chad D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Robert Harvey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nancy C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Diane Saunders",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Rebecca Lee",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Yolanda R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Benjamin Hayes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nathan F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Sandra Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Charles Ross",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Madison M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Bruce Ochoa",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Diane H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Matthew Brewer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Monica F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Thomas Carr",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jonathan L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Kaitlin Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Jared Haynes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Julie Ochoa DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gabriella D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Anthony Snyder",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Megan B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Nancy Ward",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erin L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Kristen Pham",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Charles Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robin F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Jessica Gallagher",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Don M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Glover",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Danielle Gardner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rebecca R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Holly Blackwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathy H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Christina Luna",
        "service_type": "Garage Door Opener",
        "tech_name": "Becky C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Dalton Cabrera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ryan S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Jeff Harper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Philip S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Alexis Cannon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jacob M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Kyle Green",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sharon J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Stacey Maldonado",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cody Y.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Brittney Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Shawn Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeff S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "David Henderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Katie C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Diane Lawrence",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "William Wilson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Heather G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jessica Edwards",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Casey Sherman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Debra Henson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathryn G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Paul Webb",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Vincent Stewart",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Thomas Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan O.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Ronald Hoffman",
        "service_type": "Garage Door Services",
        "tech_name": "Paige R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Sherry Meza",
        "service_type": "Garage Door Services",
        "tech_name": "Theresa L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Mrs. Kelsey Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Julia Washington",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Heather J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Kathryn Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diana W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jim Berry",
        "service_type": "Garage Door Installation",
        "tech_name": "Christian S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Mario Le",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brett N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Luke Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Margaret M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Michele Rodriguez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Kara Green",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebecca B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Casey Acosta",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Michael Lopez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Donald Sims",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Carla Riley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Alexandra Hendricks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Laura S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Kelly Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Richard Bailey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Tyler Guerrero",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Alvarez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carol J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Nathan Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Allison H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Amanda Young",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Maria Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lindsey F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Jaime Robinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patricia M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Timothy Flores",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jonathan G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Stevens",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shelby F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Jeremy Thornton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Raymond K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Michelle Knight",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jenna B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Maria Morris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Natasha C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Ashley Gonzalez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "William King",
        "service_type": "Garage Door Opener",
        "tech_name": "Charles F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Matthew Hurley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patrick D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Keith Hurst",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tammy L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Stacey Parker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rodney W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Karen Mcdowell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Suzanne B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Lucas Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kim S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Jessica James",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cole E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Casey Taylor MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jasmin D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Danielle Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sherry A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Randall Kerr",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Nicole Gates",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Abigail R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jason Carter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Kevin Rosario",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathleen H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Linda Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brett S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Angela Gonzales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connor A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Webb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erica S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Joseph Chen MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Maria P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Joshua Washington",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Regina N.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Cynthia Lang",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "John Kent Jr.",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia O.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Mary Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Taylor R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Kimberly Grant",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Alicia Riley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Guy M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Tyler Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ian T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Jacob Duke",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Deanna Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tanya M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Chris Phillips",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Terri B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Sarah Nolan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chad J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Justin Wallace",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Turner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Shawn Moreno",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Jeff Cooper",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Carol Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nichole G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "April Cain",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sonya A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Traci Atkins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Aaron G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Jason Gregory",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Michelle Schroeder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Philip K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Lisa Bishop",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Charles Reed",
        "service_type": "Garage Door Services",
        "tech_name": "Kelly G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Bobby Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monica G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Tabitha Robinson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Valerie C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Edward Perez MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeremy H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Joseph Ritter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Catherine M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Bailey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Crystal Burke",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Brian Young",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eugene L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Becky Wright",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brian P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Kelly Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Deanna M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Joshua Allen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Monica W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Cathy Holmes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shannon G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Tina Bradley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "April W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Donna Duncan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Debra Brandt",
        "service_type": "Garage Door Opener",
        "tech_name": "Tim M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Kirk Collier",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Aaron W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Holt",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicolas M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Emily Hodge",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Fitzgerald",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer Contreras",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Rachel Mccormick",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caitlin R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Edward Griffin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Francis F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Angela Carr",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "John Wright",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lauren P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Anna Burns",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sara T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Yolanda Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gina B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Charles Dean",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tammy J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Terry Delgado",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Amber Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Christopher Bridges",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Francisco M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Cynthia Love MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Keith H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "William Gray",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Drew Carter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Felicia G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Hector Morales",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Donna R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Green",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Ashley Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Justin C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Lindsey Vincent",
        "service_type": "Garage Door Off Track",
        "tech_name": "Catherine B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Ashley Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathan C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Angela Huber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Dr. Matthew Le",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelsey M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Heather Garrison MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Travis Perez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "John Kent",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Kristine Goodman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Madison Gross",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Austin M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Todd Huff",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samantha W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Rebecca Nguyen",
        "service_type": "Garage Door Opener",
        "tech_name": "Lori D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Mark Cross",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffery C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Christopher Mack",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa K.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Dawn Bryant",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Rebecca Wilcox",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Paul Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mike M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Maureen Wright",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathleen H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Katrina Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rebecca M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Madison Boyer",
        "service_type": "Garage Door Services",
        "tech_name": "Richard A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Mark Dunn",
        "service_type": "Garage Door Services",
        "tech_name": "Vincent C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Olivia Ochoa",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nathan F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Kaiser",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shane A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Kenneth Collins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bradley P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Daniel Kim",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sylvia R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Alan Howard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melanie S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Luis Dominguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kyle J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Sherri Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melissa A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Robert Rogers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kim B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Makayla Gross",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Carmen Campbell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bryan B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Julie Vargas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Zachary Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maureen B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Shannon Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Colton S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Brianna Farmer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Larry K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Jason Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cynthia M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Adam Guzman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Diane M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Sandra Edwards",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Linda S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Bradley Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Brandi Estrada",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tyler M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Dillon Cole",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Shannon Alexander",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Paula Howard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brandon M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jacob Moss",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steve Y.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Lisa Rush",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Cynthia Oliver",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julia H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Michael Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christina C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Mr. Andrew Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Frank R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Julie Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "April S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Thomas Ortega",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy O.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Dana Lewis",
        "service_type": "Garage Door Repair",
        "tech_name": "Sherry P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Melissa Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Crystal E.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Rachel Archer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Sarah Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Marilyn R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Donald Torres",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesse F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Adam Newton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Patricia Cooper",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samantha W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Brent Bass",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Wesley J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Michael Daniels",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Kelly George",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Jacob Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Karen S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Brianna Hatfield",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Benjamin F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Jerry Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristen C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Crane",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "April T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Linda Taylor",
        "service_type": "Garage Door Opener",
        "tech_name": "Alicia S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gregory L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Joseph Caldwell",
        "service_type": "Garage Door Services",
        "tech_name": "Mary H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "John Newton",
        "service_type": "Garage Door Repair",
        "tech_name": "Margaret H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Taylor Barnes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Leslie G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "William Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Marcus O.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Jessica Harrell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Kristin Figueroa",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joyce G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Stephanie Farmer",
        "service_type": "Garage Door Installation",
        "tech_name": "Derek M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Mann",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael K.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Charles Perez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Jennifer Brandt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Linda Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Sara C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Ronald Evans",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Morgan Kennedy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Janet B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Kenneth Leach",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donna A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Ellen Fisher",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Dr. Joseph Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adam M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Jamie Kim",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donald G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Cindy Stone",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kenneth P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Terri Johnston",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Kenneth Gomez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachel W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Jerry Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashlee R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Andrew Watson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tara W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Michael Kennedy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kenneth C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Daniel Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gloria C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Phyllis Randall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Heather H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "James Ruiz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Madison Stewart",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathryn R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Kelly Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "William C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Paul Stone",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ashley M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Brittany Poole",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelly W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Jeffery Porter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Herbert T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Greg Hester",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Morgan",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Kyle Gonzalez",
        "service_type": "Garage Door Repair",
        "tech_name": "Dawn W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Tonya Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Brandt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Murphy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Edward G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Peter Berry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "John Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "John T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Melissa Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jamie S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Michael Wise",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley N.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Wallace",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Dakota Cruz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tyler T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Samantha Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "David Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dustin A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Natasha Turner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emily B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Casey Rush",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carla J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Steven Aguilar",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marvin J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Amanda Kline",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Roy B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Maria Sanchez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brooke P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Sandra Hines",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Kyle Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Danielle L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Lisa Wilkerson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tony R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Leslie Lawson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Keith H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Heather Olson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maria S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Jeremy Pruitt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Danny G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Jacob Bauer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Monica B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Adam Barton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Herbert C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Peter Murray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Linda Oliver",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Norma Washington",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Isaiah S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Brittany Oconnor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Suzanne Pineda",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Shelley Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bradley S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Douglas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Reginald W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Jasmine Salazar",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christine C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "James Braun",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Grant D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Ruben Roth",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lee C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Susan Ramirez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patricia W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Jesse White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Todd M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Janet Gibbs",
        "service_type": "Garage Door Repair",
        "tech_name": "Nathan M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Lisa Spears",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kyle P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Olson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shelly F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Kelly Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christine D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Denise Schwartz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shelly H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jo Rangel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Gregory Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristy D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Leonard Bailey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Bryant",
        "service_type": "Garage Door Repair",
        "tech_name": "Wendy M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Ryan Washington",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Angela Weeks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jonathan W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Derek Murray",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Molly Farmer",
        "service_type": "Garage Door Opener",
        "tech_name": "Clifford I.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Jason Lewis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Allen Torres",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Vanessa Simmons",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angela L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Karen Patton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nancy L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Linda Petersen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Shaw",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacob N.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Brittany Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jesse C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Jordan Serrano",
        "service_type": "Garage Door Opener",
        "tech_name": "Barbara R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Heidi Glover",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Tina Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emma F.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Anthony Mosley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "James Bridges",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Molly Hoffman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Mary Burns",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ruben G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Samuel Medina",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anita H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Johnny Freeman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carolyn G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Donald Guerra",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Susan T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Erica Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "George P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Christine Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Gregory Hall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Amber Chen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Kim Yu",
        "service_type": "Garage Door Services",
        "tech_name": "Shane G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Albert Long",
        "service_type": "Garage Door Off Track",
        "tech_name": "Diana M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Sherry Bishop DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brent S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Bridget Hopkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Damon M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Erika Strickland",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mary B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Danielle Houston",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Steve Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gary M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Samuel Cooper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donna G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Courtney Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Richard Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Joshua Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wanda W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Anthony Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victoria C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Nicholas Steele",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Leslie Scott DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Adam H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Debbie H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Taylor Richardson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bonnie K.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Wood",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chad T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Kimberly Kennedy",
        "service_type": "Garage Door Repair",
        "tech_name": "Julie L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Timothy Curtis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sandra F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Collin W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Jennifer Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Connor W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Mr. Joe Gordon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julia R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Raymond Dougherty",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jared S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Donald Stephens",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Dakota Whitaker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brendan D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Veronica Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tonya J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Hannah Gaines",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeremy P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Miles",
        "service_type": "Garage Door Opener",
        "tech_name": "Allison R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Regina Barker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Robert Jordan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Erin Hill",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Matthew Mejia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Christine Bell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Trevor S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "William Lucas",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Wise",
        "service_type": "Garage Door Off Track",
        "tech_name": "Wendy F.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Bowen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Mrs. Margaret Barber",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Keith Guzman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Benjamin Barnett",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Joe Wright",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Travis Peterson",
        "service_type": "Garage Door Installation",
        "tech_name": "Dawn R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Michelle Gilbert",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alyssa L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Morgan Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Erin W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Tanya Payne",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Sheila Stone",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gary H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Miguel Quinn",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Tara Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Latasha M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Yvette Weber",
        "service_type": "Garage Door Services",
        "tech_name": "Allen R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Chelsea Wolf",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Rodney Cox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Deanna G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "William Brock",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Amber Daniels",
        "service_type": "Garage Door Opener",
        "tech_name": "Jose S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Mindy Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ariana O.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Michael Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Emily Kane",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Kristen Leblanc DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Douglas M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "John Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Thomas M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Kenneth Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Samuel Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Troy Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Andrew Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Brian Crawford",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pamela W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Eric Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Joann P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Noah Hansen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jimmy G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Christian Macdonald",
        "service_type": "Garage Door Opener",
        "tech_name": "Stacey K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Mejia",
        "service_type": "Garage Door Opener",
        "tech_name": "James H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Cynthia Rogers",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Joseph Ewing",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Charlene Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Ruth Alexander",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nancy H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Angela Horton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Yvonne Holder",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julie T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Mindy Rodriguez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Frederick Baker",
        "service_type": "Garage Door Services",
        "tech_name": "Glenda H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Willie Burnett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tanya R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Michael Arias",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Juan D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Julia Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Haley S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Lucas",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Jacob Alexander",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Jamie Ewing",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Madison Schaefer",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Miranda Orozco",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Devon Garcia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sheila S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Kevin Gill",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Kimberly Singh",
        "service_type": "Garage Door Repair",
        "tech_name": "Barbara H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Eric Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jamie R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Jennifer Nunez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jasmine M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Anthony Barker",
        "service_type": "Garage Door Opener",
        "tech_name": "David M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Tyrone Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Kristina Figueroa",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dale C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Darlene Lawrence",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Kendra Bryant",
        "service_type": "Garage Door Installation",
        "tech_name": "Erin T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Lawrence Jenkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Russell A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Isaiah Martinez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Monica C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Wayne Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Helen Price",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brenda W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Hannah H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Heather Gates",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathleen A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Mark Bush",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dennis D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Michelle Ali",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tyler A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Kayla Craig",
        "service_type": "Garage Door Insulation",
        "tech_name": "William F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Zachary Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Gregory P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Richard Morris",
        "service_type": "Garage Door Installation",
        "tech_name": "Ralph S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Cynthia Cook",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Joshua Burton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrew J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Leslie Sanders",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Tammy Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kelsey J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Miss Patricia Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "William Cortez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tracey H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Sydney Nguyen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "David L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Ruben Schmidt",
        "service_type": "Garage Door Opener",
        "tech_name": "Karen H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Zachary Rodriguez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kim S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Richard Gutierrez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julie G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Troy Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Katherine Suarez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Morgan Craig",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lindsey A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Rachel Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Susan H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Kathleen Hurst",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Timothy Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tiffany W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Jill Wagner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melanie K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Robert Curry",
        "service_type": "Garage Door Opener",
        "tech_name": "Bryan M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Paul Garza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robin C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Katherine Salas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Amber Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gabriel D.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Betty Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Adam Morales",
        "service_type": "Garage Door Installation",
        "tech_name": "Kathleen N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Nicholas Berger",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Jordan Villegas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alyssa H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Destiny Evans",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Andrea Schultz",
        "service_type": "Garage Door Repair",
        "tech_name": "Susan M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Brandon Chen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Todd Smith DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Juan C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Maria Morris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Travis K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Patrick Hall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carol C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Austin Mays",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelsey M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Jessica Tanner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Karen M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Dr. Thomas Fisher",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Renee B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Amy Stout",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carla H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Robert Jenkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Katie Scott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Garrett P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Misty Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sandra G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Lindsey Joseph",
        "service_type": "Garage Door Installation",
        "tech_name": "Roy P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Robin Woods",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "George Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ivan B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Chelsea Simmons",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samuel G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Nancy Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandy H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Michael Knapp",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Holly J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Tyler Price",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark V.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Jose Hamilton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristen M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Blake Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Melanie Kirby DDS",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Danielle F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Chelsea Ramos",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Nancy Reyes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Marcus Carlson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Aaron Porter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Gregory Palmer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Paul Michael",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Kimberly Walton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chad R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Gabrielle Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Leah J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Donna Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Debbie B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Emma Owens",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kristin W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Lisa Mcmillan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Leslie M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Corey Barton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashley J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Angel Hart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicole B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Charles Butler",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rita K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Veronica Hoffman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Mark Waters",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Suzanne Andrews DVM",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sean C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Alicia Torres",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Virginia M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Timothy Novak",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Holly Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Connie Thompson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carolyn M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Diane Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Cynthia Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "George Cross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Robert Holmes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Misty M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Bush",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Thomas Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carrie O.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Tim White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Tracie Mitchell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Alexandria Flores",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dana E.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "David Hawkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mariah J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Steven Pierce",
        "service_type": "Garage Door Repair",
        "tech_name": "Tonya M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Amber Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Benjamin Shaw",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tyler L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "William Morgan",
        "service_type": "Garage Door Services",
        "tech_name": "Crystal V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Sean Olsen",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Edwards",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gregory K.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Isabel Lewis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Nguyen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Amy Day",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christina G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Rodney Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Emily Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shirley P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Hannah Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Suzanne F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Erin Stark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brenda H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Kristen Mcclure",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cindy R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Hannah Clark",
        "service_type": "Garage Door Repair",
        "tech_name": "Joyce V.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Sandra Gonzales",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Justin Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Jay Cruz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Natasha P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Douglas Duncan MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lance L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Heather Delgado",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Ryan Nolan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathan O.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Taylor Wise",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Melissa Barber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "William Robertson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Abigail S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Jonathan Barron",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rachel G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Jeremy Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Daniel Murphy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nathan K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Eileen Santiago",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Steven T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Eric Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Jonathan Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Erik Whitney",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Jeffery Wiley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Makayla Reese",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jonathan N.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Carolyn Cain",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "David Carter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Tina Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Edward C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Michelle Mckee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alejandro M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Danielle Calhoun",
        "service_type": "Garage Door Installation",
        "tech_name": "Barry N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Maurice Braun",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tina T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "John Kane",
        "service_type": "Garage Door Repair",
        "tech_name": "Peggy L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Leslie Hull",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephen B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Kelly Chavez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lindsay B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "James Navarro",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Maria Conley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Juan G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Katrina Lambert",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ethan M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Larry Weiss",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Dominique Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Casey B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Erika Armstrong DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mario H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Natalie Kelly",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Evelyn V.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Mrs. Lisa Ruiz",
        "service_type": "Garage Door Opener",
        "tech_name": "Derek R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Kylie Stewart",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathy C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Corey Bowman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandi R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Dylan Caldwell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Barbara B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Ronald Wolfe",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Anthony Weiss",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica Y.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Kurt Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Julie Alexander",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Amy Gallagher",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Zachary B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Johnny Pollard",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Carolyn L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Denise Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sheila J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Anthony",
        "service_type": "Garage Door Installation",
        "tech_name": "Laura H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Samantha Sellers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brittany M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Amy Reynolds",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Kyle Parker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew Z.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Brian Lynn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Erika Rodriguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Becky Cox DVM",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Lisa Scott",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Russell Y.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Nicholas Hansen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Megan T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Brian Bailey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tina R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Melissa Campos",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alexander G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jennifer Wolf",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Rachel Benson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Mrs. Rebekah Turner",
        "service_type": "Garage Door Services",
        "tech_name": "Kaitlyn J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "David Sosa",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Catherine Johnson PhD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebecca S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Kristine Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Chad Reed",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah K.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Adam Moore",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Andrew Flores",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Valerie W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Olivia Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Mary Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anna V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Lori Cisneros",
        "service_type": "Garage Door Installation",
        "tech_name": "Sean H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Jimmy Maldonado",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Kristin Lucero",
        "service_type": "Garage Door Opener",
        "tech_name": "Vincent J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Carol Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Philip C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Thomas Mcclain",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Juan M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Kevin Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Morgan Rogers MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Craig",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jerry D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Angela Wright",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charles F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "David Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Clarence R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Mark Gibson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "William Daugherty",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jenna C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Jose Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Megan H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Laura Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicole A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Patricia Brewer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Mark Beck",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sydney D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Bailey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Travis Sanchez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lynn D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Lori Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julia H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Lindsey Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Madison W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Richard Cordova",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Christopher Barber",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shawn P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Chris Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shane M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Sandy Erickson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jacob Burton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Robin Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John T.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Linda Le",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alec L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Charles Riddle",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leonard F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Patricia Barker",
        "service_type": "Garage Door Repair",
        "tech_name": "Samantha V.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Samuel Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Aaron B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Leslie Hall DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christina C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Russell Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "Caleb W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Sarah Haynes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Debra R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Dean Hines",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Evan Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Jamie W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Richard Sexton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cathy G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Kristen Ramirez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Veronica L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Tyler Conley",
        "service_type": "Garage Door Repair",
        "tech_name": "Dustin M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Blake Bernard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Theresa M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Jessica Le",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Jose Chapman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Derrick H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Joseph Rosales",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jacqueline C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Lucas Moon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Benjamin G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Linda Stone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Gabriela Compton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brenda B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Daniel Horton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Antonio J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "James Ruiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Trevor F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Michael Callahan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samuel R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Chelsea Riley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patricia A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Gregory Maldonado",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "David Austin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Danny Ford",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Paula Franco",
        "service_type": "Garage Door Installation",
        "tech_name": "David C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Roger Wright",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Hudson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kirsten W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Matthew Foster",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joel R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Robert Sharp",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Daniel Meyer",
        "service_type": "Garage Door Repair",
        "tech_name": "Jesse W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Gerald Watson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Cindy Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacob W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Stephen Young",
        "service_type": "Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "John Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carly G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Ian Sims",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lauren S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Julie C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Danielle Evans",
        "service_type": "Garage Door Opener",
        "tech_name": "Vanessa R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Howard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Hannah R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Tyler Woodard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melanie C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Laurie Hanson",
        "service_type": "Garage Door Installation",
        "tech_name": "Sonia M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Robert Larson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heidi W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Amy Stevens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Matthew Stanley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John T.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Frank Sanders",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Leslie H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Justin Young",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Max A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Melissa Harding",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Gutierrez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raymond C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Santos",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Michael Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Jackie Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michaela R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Ms. Lisa Price MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katrina D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Rachel Richmond",
        "service_type": "Garage Door Insulation",
        "tech_name": "Clayton H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Ashley Evans",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Christopher Acosta",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Katherine Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Rodney Maldonado",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Joseph Jensen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Latoya M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Dr. Fernando Bell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Wesley Berg",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rodney J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Justin Garcia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jane P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Scott Krueger",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Valerie W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "David Gordon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Benjamin D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Devin Hernandez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amber A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Rebecca Li",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brendan H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Eduardo Nguyen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julian R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Chad King",
        "service_type": "Garage Door Opener",
        "tech_name": "Amber Z.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Theodore Bryant",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jasmine B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Teresa Henderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Collin C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Jonathan Santiago",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Kimberly Elliott",
        "service_type": "Garage Door Repair",
        "tech_name": "Marisa R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Chris Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Kimberly Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "John Cox",
        "service_type": "Garage Door Off Track",
        "tech_name": "Matthew K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Tyler Wheeler PhD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Karen F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Wells",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Miguel Espinoza",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Donna Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carrie M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Joseph Robinson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brooke G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Olivia Walker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shawn H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Todd Adkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adam R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Suzanne Pierce",
        "service_type": "Garage Door Installation",
        "tech_name": "Sherry J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Jesse Petersen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Zachary B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jared K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Joseph Cooke",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tiffany S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Daniel Allison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jill C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Sara Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Kevin Jacobs",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Donald Strong",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Teresa Frey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "April Kennedy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Justin M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Colleen Sanchez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Tracey Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jesse W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Charles Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Harold G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Bonnie Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Patricia Webb",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Kristin Schmidt",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Steven Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Walker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jamie Frost",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tyler W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Diana Pacheco",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Mary Burke",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brandon K.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Kelly Torres",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Douglas Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Jason Compton MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tiffany B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Michelle Rasmussen",
        "service_type": "Garage Door Opener",
        "tech_name": "Barbara M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Timothy Fisher",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Brandon Hughes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kayla White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Linda Nunez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Jessica Harris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Kevin Wallace",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephanie C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Steven Wagner",
        "service_type": "Garage Door Off Track",
        "tech_name": "William J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Thomas Marsh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Steven B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jeremy Lyons",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "David Warren",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Karen J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Holland DVM",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kayla B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Jenny Contreras",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Jacob Hahn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Juan E.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Crystal Rodriguez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kyle B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Amber Freeman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Cesar Daniels",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bradley J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Troy Lam",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Pamela S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Angela Daniel",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Benjamin Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Chris B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Erin Tran",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Whitney V.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Lisa Mills",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Robin Graves",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christine L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Sheila Richards",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Dustin Reilly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Samantha Drake",
        "service_type": "Garage Door Opener",
        "tech_name": "Samantha W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jeremy Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Ruth E.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Timothy Blair",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christine A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Stephanie Flores",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah E.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "James Mason",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Richard Woodard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kara P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Dale Gordon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "James Elliott",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Phillip Lee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrea C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Jeffery Jenkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Erik Phillips",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julia S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Kim Wheeler",
        "service_type": "Garage Door Services",
        "tech_name": "Ryan V.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Emily Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Scott Y.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Marc Rhodes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bonnie H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Nancy Hernandez MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Craig S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Thomas English",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Barbara G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Benjamin Warner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Sean Owen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Timothy F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Michelle Cannon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Debbie K.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Christopher Strickland",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kayla M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "William Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Lisa Pena",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dawn K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Ms. Jennifer Brown DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bryan N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Benjamin Harvey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Douglas G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Claudia Freeman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexis D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Nancy Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Mark Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Johnny W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Veronica Goodwin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julie F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Anthony White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lacey T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Victor Rich",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Benjamin Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "James Adams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kathy Q.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Timothy Gomez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carla R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Joseph Wright",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samuel T.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Paul Hartman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angel L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Michael Peterson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Marisa B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Amy Sellers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Timothy H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Kevin Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Rebecca B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Joy Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rhonda S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Bryan Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tyler B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Andrew Jacobs",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Timothy Jensen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mitchell J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Joseph Shaw",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tiffany A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Michelle Richardson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rachel N.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Wayne Roth",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward V.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Matthew Cobb",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Felicia Walton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Hannah B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Susan Daniels",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelly F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Michael Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexandra M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Ebony Blanchard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angel T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Dennis Hall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Taylor Chavez",
        "service_type": "Garage Door Installation",
        "tech_name": "Dorothy A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Andrea Snyder",
        "service_type": "Garage Door Installation",
        "tech_name": "William H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Cynthia Dominguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Joshua Cook",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexander W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Emily Howell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly Y.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Amanda Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Johnson PhD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alicia G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Anthony White",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Charles Mora",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Tabitha Hansen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Juan C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Matthew White",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Donald Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Pam J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julie C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Kurt Mclean",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gail R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "David Vaughn",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Jonathan Patterson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Harold H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Jared Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "George L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Ashley Hart",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Chambers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tamara L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Justin Henderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Margaret S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Richardson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Virginia S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Zachary Ryan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tanner W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Dr. Kimberly Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Danielle R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Christopher Davidson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremiah B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Mark Gutierrez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Linda N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Jennifer Mejia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Robert Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Alison Perkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Frank F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Douglas Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sandra S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Mary Mendez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Johnathan G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Erika King",
        "service_type": "Garage Door Opener",
        "tech_name": "Nathan W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Annette Manning",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Connie B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Hicks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachel C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Bradley Peters",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Leslie Novak",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Brandi Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sandra F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "John Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathan E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Cody Welch",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kurt L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Jason Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristopher S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie Cummings",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Amy Jacobs",
        "service_type": "Garage Door Installation",
        "tech_name": "John T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Gregory Gibson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Katherine T.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Ryan Fernandez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacob C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Briana Hart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Misty K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Angela Le",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Christopher Torres Jr.",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Amanda Barker",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Vanessa Dillon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melody C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Johnathan Mathews",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathryn G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "Victoria B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "James Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angel B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Melvin Morris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Brian Duncan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Edward M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Jeff Sanchez",
        "service_type": "Garage Door Opener",
        "tech_name": "Cassandra K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "William Church",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Nicole Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bethany M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Shelby Sweeney",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric V.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Jose Coffey",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Edwin Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Randy Allen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Nancy Peterson",
        "service_type": "Garage Door Repair",
        "tech_name": "Carl G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Edward Drake",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "John Gross",
        "service_type": "Garage Door Installation",
        "tech_name": "Gregory A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Angela Henry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Darryl F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Kayla Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jill J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Erica Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dawn K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Carlos Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachel P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Melissa Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Paul Reeves",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gina C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Michelle Nicholson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jesus F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Scott Todd",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Nancy Shelton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Newman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew Y.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Andrew Neal",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Maria C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Colleen Henry",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Richard Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandi W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Brandy Wagner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Vanessa C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Charles Walker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacy L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Brian White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Patrick B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Michael Butler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Curtis H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Thomas Hardin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jackie L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Jason Hughes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cynthia M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Mike Fisher",
        "service_type": "Garage Door Services",
        "tech_name": "Lisa G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Steven Sherman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Debbie B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Reynolds",
        "service_type": "Garage Door Services",
        "tech_name": "Jonathan M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Maria Brady MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gerald S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Angela Pacheco",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rachel B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Shannon Campbell",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher O.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Heather Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debra G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Michelle Wright",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sonia A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Amy Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Alyssa H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Charles Thomas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ricardo B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Michael Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Frederick Stevens",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Stephanie Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Shawn Holmes",
        "service_type": "Garage Door Repair",
        "tech_name": "Billy M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Paula Mitchell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Karen R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "David Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Thomas Henderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Brianna Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cheryl G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Todd Hanson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Justin Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "William Whitehead",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Alexander",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Mr. Joseph Morris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jodi G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Kirk Gould",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Patricia Woods",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Christina Graham",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Keith S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Samantha Allen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrea R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Christina Hamilton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tracy S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Diane Herman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samuel A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Stephanie Allen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Nicole Bridges",
        "service_type": "Garage Door Off Track",
        "tech_name": "Teresa J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Francisco Stephens",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "James Sullivan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kayla R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Douglas Estrada",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Courtney S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "David Mueller",
        "service_type": "Garage Door Services",
        "tech_name": "Alexander P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Hannah Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Troy S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Paul Nelson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Kenneth Robertson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Katherine R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Jasmine Obrien",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Austin L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Phillip Irwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Juan J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Angela Mcdonald",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Russell C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Jonathan Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Phillip W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Deborah Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Wallace",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tim R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Christopher James",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristin M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Dawn Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Melissa Mann",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christian M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Rebecca Buck",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julie J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Sara Hutchinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christine J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Amy Kemp",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Richard Anthony",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Christine Weaver",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Jimmy Ortiz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Johnny A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Patricia Green",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Travis H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hayes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sara B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Bryan Reynolds",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandon K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Nicole Strickland DDS",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Dillon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen N.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Kevin Barker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "William Mays",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Diana V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Kayla Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lawrence S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Raymond Mercado",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Connie Holland",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amber K.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Gina Noble",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alex C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Todd Acosta",
        "service_type": "Garage Door Services",
        "tech_name": "Cassandra A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Dylan Davila",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jonathan M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Quinn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Kayla Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chad H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Cameron Booker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David O.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Linda Morris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Valerie S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Molly Herrera",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Kathleen Kennedy",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Clinton F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Jocelyn Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Joshua Price",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lindsay B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Dennis Gonzalez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Benjamin G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Michael Murphy",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Renee R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Benjamin Villa",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michelle D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Donna Cain",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Antonio Oneill",
        "service_type": "Garage Door Repair",
        "tech_name": "Jonathan A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Amanda Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Marcus F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Julia Pierce",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ashley B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Rhonda Sullivan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Shannon Yates",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carla W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Sandra Kline",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Natalie S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Marcus White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Michael Reeves",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Denise O.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Paige Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Taylor Barber",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carolyn D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Powell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "John Willis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Denise M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Sean Ray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samantha N.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Kayla Bailey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nina H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Thomas Wheeler",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Robin Moon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Raymond Patterson",
        "service_type": "Garage Door Services",
        "tech_name": "Jody L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Lori West",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sarah D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Dana Marshall",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean E.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Javier Schmitt",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sabrina G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "David Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "David Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Angela L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Kristen Brooks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anna R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Erik Wright",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sherri D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Sabrina Sanders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chase R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Michael Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shawn W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Samuel Frye",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Jessica Mora",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Timothy Simmons",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erin H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Benjamin Harris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Zachary Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Cathy Thompson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Caleb R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Alexa Charles",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Paul Bryant",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "David Green",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Kelly White",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shelby B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Courtney Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carolyn P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Calvin Frazier",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary O.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Nicole Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jared J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Sabrina Bolton",
        "service_type": "Garage Door Repair",
        "tech_name": "Ana W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Amber Nash",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tammy M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Karen Banks",
        "service_type": "Garage Door Services",
        "tech_name": "John P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Scott Nash",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Keith Mcdonald",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karen D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Yvette Simon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jose A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Melissa Bell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joel W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Justin Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
