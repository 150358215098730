import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ServicesAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import GarageImg3 from '../../Images/GarageImg3.jpg'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '..//ServicesPage/AreaData'

export default function ServicesAll() {
    const navigate = useNavigate()


    return (
        <div className="ServicesAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Garage Door Services | Moreno Valley Garage Door - Installation, Inspection & Repair</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Garage Door Services | Moreno Valley Garage Door - Installation, Inspection & Repair" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Garage Door Services | Moreno Valley Garage Door - Installation, Inspection & Repair" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.garagedoormorenovalley.org/Reviews by our customers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the full range of professional garage door services offered by Moreno Valley Garage Door. From installations and inspections to repairs and maintenance, we ensure your garage door operates safely and efficiently." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the full range of professional garage door services offered by Moreno Valley Garage Door. From installations and inspections to repairs and maintenance, we ensure your garage door operates safely and efficiently." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Garage Door Services | Moreno Valley Garage Door - Installation, Inspection & Repair" data-react-helmet="true" />
                <meta name="keywords" content="Garage Door, Garage Door Installation, Garage Door Inspection, Garage Door Repair, Garage Door Maintenance, Garage Door Opener Installation, Garage Door Off Track Repair, Garage Door Spring Repair, Garage Door Remote Programming, Garage Door Roller Replacement, Garage Door Weather Seals, Garage Door Services, Garage Door Safety, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="ServicesAllTitle">
                <h1>Comprehensive Garage Door Services in Moreno Valley, CA</h1>
                <h2>At Moreno Valley Garage Door, we offer a full range of professional garage door services to keep your home safe, secure, and functional. Whether you need a new garage door installation, a thorough inspection, or expert repairs, our skilled technicians are here to provide top-quality service. From routine maintenance to addressing complex issues, we ensure your garage door is in optimal condition. Explore our services to find the right solution for your home, and trust Moreno Valley Garage Door to deliver the excellence and reliability you deserve.</h2>
            </div>
            <div className="ServicesAllLsit">
                {AreaData.map((A, index) =>
                    <a href={A.Path} className="Header1BoxMenuBox">

                        <div className="ServicesAllBox">
                            <h2>{A.Title}</h2>
                            <h6>{A.TitleShortVery}</h6>
                            <h4>{A.Full}</h4>
                            <h5>EXPLORE THIS SERVICE</h5>
                        </div>
                    </a>
                )}
            </div>

            <NavigatorPath />

        </div>
    )
}

