import React, { useEffect, useRef, useState } from 'react'
import './LocationAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import LocationData from './LocationData'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'

//
// import GoogleMapReact from "google-map-react";

export default function LocationAll() {
    const navigate = useNavigate()

    return (
<div className="LocationAll">
    <Helmet>
        {/* <!-- TITLE -->  */}
        <title>Moreno Valley Garage Door | Our Garage Door Locations</title>
        <meta name="title" property="title" content="Moreno Valley Garage Door | Our Locations" data-react-helmet="true" />
        <meta name="og:title" property="og:title" content="Moreno Valley Garage Door | Our Locations" data-react-helmet="true" />
        {/* <!-- META --> */}
        <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
        <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
        <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
        <meta name="og:url" property="og:url" content="https://www.garagedoormorenovalley.org/locations" data-react-helmet="true" />
        <meta name="description" content="Discover Our Locations At Moreno Valley Garage Door | Garage Door Repair in Moreno Valley, CA area and surrounding cities." data-react-helmet="true" />
        <meta name="og:description" property="og:description" content="Discover Our Locations At Moreno Valley Garage Door | Garage Door Repair in Moreno Valley, CA area and surrounding cities." data-react-helmet="true" />
        <meta name="keywords" content="Garage door repair, Garage door service, Garage door installation, Garage door maintenance, Garage door springs, Garage door opener repair, Emergency garage door repair" data-react-helmet="true" />
        <meta name="og:site_name" property="og:site_name" content="Moreno Valley Garage Door" data-react-helmet="true" />
    </Helmet>
    
    <div className="MainBanner">
        <div className="MainBannerLeft">
            <h1>OUR LOCATIONS</h1>
            <h2>Welcome to Moreno Valley Garage Door Service Provider</h2>
            <span>
                <a href="tel:951-499-1642">CALL 951-499-1642<img src={telephone} alt="" /></a>
                <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
            </span>
            <span className="GoogleButt">
                <h6>5 Stars - Based on 3,618 Reviews</h6>
                <h6 className="GoogleButtBooms">
                    Within 5 mi • Within 20 mi • Open now • Residential • Top rated • Prices • Online estimates • Replacement
                </h6>
            </span>
        </div>
        <div className="BookAnAppointmentMainComponentEmergery">
            <div className="BookAnAppointmentMainComponentRates">
                <div className="BookAnAppointmentMainComponentRatesImages">
                    <img src={YELP} alt="Moreno Valley Garage Door Yelp Review" />
                    <img src={Angie} alt="Moreno Valley Garage Door Angie Review" />
                    <img src={HomeAdvisor} alt="Moreno Valley Garage Door HomeAdvisor Review" />
                    <img src={BBB} alt="Moreno Valley Garage Door BBB Review" />
                    <img src={Google} alt="Moreno Valley Garage Door Google Review" />
                    <img src={Porch} alt="Moreno Valley Garage Door Porch Review" />
                    <img src={Trust} alt="Moreno Valley Garage Door Trust Review" />
                </div>
                <h1>Customer Testimonials</h1>
                <h2>⭐⭐⭐⭐⭐</h2>
                <h3>
                    "I’ve used Moreno Valley Garage Door for both repairs and maintenance, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My garage door has never functioned better, and I appreciate their commitment to safety and customer satisfaction."
                    <a href={`/reviews/${LocationData.find(a => a.City == ("Moreno Valley").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>MORE FROM OUR CUSTOMERS &gt;</a>
                </h3>
            </div>
            <div className="BookAnAppointmentMainComponentEmergeryBox">
                <a href="tel:951-499-1642">For emergency service call: 951-499-1642</a>
            </div>
        </div>
    </div>

    <div className="LocationAllListMap">
        <div className="LocationAllLsit">
            <div className="LocationAllBox1 LocationAllBox">
                <div className="LocationAllBoxLeft">
                    <h2>Moreno Valley, CA</h2>
                    <h3>Open Now 24/7 • Top Rated • Same Day Service</h3>
                    <h4>Serving Garage Door Repair in Moreno Valley, CA area and surrounding cities.</h4>
                    <h5><a href='/'>VISIT THIS LOCATION</a> - <a href={`/services`}>SEE SERVICES</a></h5>
                    <span>
                        <a href={`tel:951-499-1642`}>
                            <img src={telephone} alt={`951-499-1642 - Moreno Valley Garage Door`} />951-499-1642
                        </a>
                        <a href={`/book`}>
                            <img src={book} alt={`SCHEDULE ONLINE Moreno Valley Garage Door`} />SCHEDULE ONLINE
                        </a>
                        <a href={`/reviews/${LocationData.find(a => a.City == ("Moreno Valley").replace("%20", '')).City.replace(" ", '').toLowerCase()}`} >
                            <img src={locationpin} alt={`Read reviews of Moreno Valley Garage Door`} />READ REVIEWS
                        </a>
                    </span>
                </div>
                <div className="LocationAllBoxRight">
                    <div className="Mapsss">
                        {/* Optional: Include a map or relevant location image here */}
                    </div>
                </div>
            </div>
            {LocationData.length ? LocationData.map((A, index) =>
                <div key={index} className="Header1BoxMenuBox">
                    <div className="LocationAllBox">
                        <div className="LocationAllBoxLeft">
                            <h2>{A.City}, {A.State}</h2>
                            <h3>Open Now 24/7 • Top Rated • Same Day Service</h3>
                            <h4>Serving Garage Door in {A.City}, CA area and surrounding cities.</h4>
                            <h5><a href={A.Url} target='_blank'>VISIT THIS LOCATION</a> - <a href={`${A.Url}/services`} target='_blank'>SEE SERVICES</a></h5>
                            <span>
                                <a href={`tel:${A.Phone}`}>
                                    <img src={telephone} alt={`${A.Phone} - ${A.City} Garage Door`} />{A.Phone}
                                </a>
                                <a href={`${A.Url}/book`}>
                                    <img src={book} alt={`SCHEDULE ONLINE ${A.City} Garage Door`} />SCHEDULE ONLINE
                                </a>
                                <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >
                                    <img src={locationpin} alt={`Read reviews of ${A.City} Garage Door`} />READ REVIEWS
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    </div>

    <NavigatorPath />
</div>

    )
}

